import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    shaoxingone:true,
    street:false,
    streetone: false,
    peopleone:false,
    peopletwo:false,
    peoplethree:false,
    stadiumone:false,
    stadiumtwo:false,
    stadiumthree:false,
    touristwalk:false,
    familyfitness:false,
    eventsone:false,
    sportindustry:false,
    association:false,
    socialinstructor:false,
    athlete:false,
    nationalfitness:false,
    outdoorpain:false,

    event:false,    // 赛事  越城国际荧光酷跑节 — 弹框
    slideshow: false,    // 赛事 图片
    asspopout:false,      // 协力——弹窗
    homeData: {
      "area_total": 0,//体育场地总面积（平方米）
      "population_total": 0,//总人口
      "regular_exercise_population": 0,//经常性锻炼人口
      "regular_exercise_population_proportion": 0,//经常性锻炼人口占比
      "sportsman_main_sum": 0,//运动员总人数
      "resumption_num": 0,//社会指导员人数
      "resumption_cishu": 0,//社会指导员次数
      "resumption_lv": 0//社会指导员履职率
    },
    dataCon: {
      "constitution": 0,
      "lottery": 0,
      "sitearea": 0,
      "siteareap": 0,
      "trophy": 0,
      "video": []
    },
    areaDataCon: {
      "constitution": 0,
      "lottery": 0,
      "sitearea": 0,
      "siteareap": 0,
      "trophy": 0,
      "video": []
    },
    area: {
      area_id: '', //区域id ,之前韩信的接口使用的是 area_id
      area_type: '', //和区域id差不多，后来老君的接口使用 area_type  0=市本级，1=越城区，2=柯桥区，3=上虞区，4=诸暨市，5=嵊州市，6=新昌县
    },
    siteArea: {
      '上虞区': 0,
'嵊州市': 0,
'新昌县': 0,
'柯桥区': 0,
'诸暨市': 0,
'越城区': 0,
    }
  },
  mutations: {
    homeData(state, payload) {
      state.homeData = payload;
    },
    siteArea(state, payload) {
      state.siteArea = payload;
    },
    dataCon(state, payload) {
      payload.constitution = Number(payload.constitution)
      payload.lottery = Number(payload.lottery )
      payload.sitearea = Number(payload.sitearea)
      payload.siteareap= Number(payload.siteareap)
      payload.trophy = Number(payload.trophy)
      state.dataCon = payload;
    },
    areaDataCon(state, payload) {
      payload.constitution = Number(payload.constitution)
      payload.lottery = Number(payload.lottery )
      payload.sitearea = Number(payload.sitearea)
      payload.siteareap= Number(payload.siteareap)
      payload.trophy = Number(payload.trophy)
      state.areaDataCon = payload;
    },
    area(state, payload) {
      state.area = payload;
    },
  },
  actions: {
  },
  modules: {
  }
})
