<template>
  <div id='login'>
      <div class="title">{{title}}</div>
      <div class="flex">
        <input type="text" v-model="user" placeholder="请输入用户名" class="inp user" @keyup.enter="login">
      </div>
      <div class="flex">
        <input type="password" v-model="password" placeholder="请输入用户密码" class="inp pwd" @keyup.enter="login">
      </div>
      <div class="btn" @click="login">登录</div>
      <div class="tip" v-if="tipShow">{{content}}</div>
  </div>
</template>

<script>

export default {
    name: 'login',
    data() {
        return {
            title:"",
            user:"",
            password:"",
            content:"",
            tipShow:false
        }
    },
    methods: {
        login(){
            let that = this;
           
            if(that.user.length <= 0){
                that.content = '用户名不能为空';
                that.tipShow = true
                setTimeout(() => {
                    that.tipShow = false;
                }, 2000);
            }else if(that.password.length <= 0){
                that.content = '密码不能为空';
                that.tipShow = true
                setTimeout(() => {
                    that.tipShow = false;
                }, 2000);
            }else{
                var data = {
                    account:that.user,
                    password:that.password
                }
                let result = window.ajaxRequest(window.host+"map/screen/login",data,"POST")
                // result = JSON.parse(result)
                // console.log(result)
                if(result.result.code == 200){
                    // that.$router.push('/home')
                    // window.initLoading();
                    // setTimeout(()=>{
            		// 	location.reload() 
                    // },100)
                    window.localStorage.setItem("screenUserInfo", JSON.stringify(result.data));
                    // this.$cookies.set("screenUserInfo",JSON.stringify(result.data))
                    let redirect = decodeURIComponent(this.$route.query.redirect || '/home');
                    // console.log(redirect)
                    this.$router.push({path:redirect})
                    // sessionStorage.setItem("screenUserInfo", result.data);
                }else{
                    that.content = result.result.msg;
                    that.tipShow = true
                    setTimeout(() => {
                        that.tipShow = false;
                    }, 2000);
                }
            }
        }
    },
    mounted () {
        // let cook = this.$cookies.get("screenUserInfo");
        // if(cook){
        //     window.localStorage.setItem("screenUserInfo", JSON.stringify(cook));
        // }
        window.windowResize();
        if(window.localStorage.getItem("screenUserInfo") != null){
            this.$router.push('/home')
        }
        this.title = window.PageTitle;
    }
}
</script>

<style scoped>
  #login{
        position: absolute;
        width: 100vw;
        height: 100vh;
        background: url('../assets/images/loginBack.png') no-repeat;
        background-size: 100% 100%;
  }
  .title{
        color: #20D7FE;
        font-size: 0.5rem;
        font-weight: bold;
        margin-top: 3.45rem;
        margin-left: 1.71rem;
        margin-bottom: 0.97rem;
  }
  .inp{
        outline: none;
        width: 4.13rem;
        height: 0.53rem;
        border-radius: 1rem;
        padding-left: 0.2rem;
        margin-left: 2.57rem;
        text-align: center;
        font-size: 0.2rem;
        color: #666666;
        border: 0;
        background-color: white;
        background-repeat: no-repeat;
        background-size: 0.3rem 0.3rem;
        background-position: 0.2rem 0.12rem;
  }
  .user{
        background-image: url('../assets/images/loginUser.jpg');
  }
  .pwd{
        background-image: url('../assets/images/loginPwd.jpg');
  }
  .btn{
        width: 2.82rem;
        height: 0.6rem;
        line-height: 0.6rem;
        text-align: center;
        background:#20D7FE ;
        color: white;
        border-radius: 0.3rem;
        font-size: 0.28rem;
        margin: 0.64rem 3.28rem;
        cursor: pointer;
  }
  .flex{
        display: flex;
        align-items: center;
        margin: 0.32rem auto;

  }
  .flex span{
        color: white;
        display: inline-block;
        line-height: 0.4rem;
        background-color: red;
        margin-left: 0.3rem;
        padding: 0 0.2rem;
        border-radius: 0.5rem;
  }
  .tip{
      width: 4rem;
      line-height: 0.5rem;
      color: white;
      position: absolute;
      top: 2.8rem;
      left: 3rem;
      background-color: #ee3232E5;
      text-align: center;
      border-radius: 1rem;
      font-size: 0.2rem;
  }
</style>