import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'
import login from '../views/login.vue'

Vue.use(VueRouter)

var defaultTitle = "绍兴市智慧化数字体育平台"

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home'),
    meta: {loginRequest: true}
  },
  {
    path: '/venues',
    name: 'venues',
    component: () => import('../components/home/stadiumone.vue'),
    meta: {loginRequest: true}
  },
  {
    path: '/swimming',
    name: 'swimming',
    component: () => import('../components/home/swimmingpool.vue'),
    meta: {loginRequest: true}
  },
  {
    path: '/index',
    name: '首页',
	  meta:{pagetitle:"首页-"+defaultTitle},
    component: () => import('../views/index'),
    meta: {loginRequest: true}
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  document.getElementById('public_title').innerHTML = to.meta.pagetitle === undefined ? defaultTitle : to.meta.pagetitle
  next();
})
export default router
